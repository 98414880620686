import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Comman/Sidebar";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";
import Web3 from "web3";
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function Index() {
  const { toastSuccess, toastError, tokenRate, dbuser, pkgs, } = useContext(ProjectContext);
  const [dashboardData, setDashboardData] = useState(null);
  const [settingData, setSettingData] = useState(null);
  const [ownerContractBal, setOwnerContractBal] = useState(0);
  const [address, setuaddress] = useState("");
  const [amount, setamount] = useState(0);
  const [spkg, setspkg] = useState(null);
  const [trdoneSucc, settrdoneSucc] = useState(false);
  const [trdone, settrdone] = useState(false);
  const [tandc, settandc] = useState(false);
  const [referral, setreferral] = useState("");
  const [showPackage, setShowPackage] = useState(false);
  const [showTopUp, setShowTopUp] = useState(false);


  const getDashboardData = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "admindashboard",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
        })
        .then((res) => {
          // console.log("res.data", res.data);
          if (res.data.error) {
            console.log("res.data.error", res.data.error);
          } else {
            setDashboardData(res.data);
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const getSettingData = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "setting",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
        })
        .then((res) => {
          // console.log("res.data", res.data);
          if (res.data.error) {
            console.log("res.data.error", res.data.error);
          } else {
            setSettingData(res.data.data);
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };
  const updateSettingData = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "setting",
          submethod: "update",
          key: process.env.REACT_APP_KEY,
          status: !settingData.withdrawal,
        })
        .then((res) => {
          // console.log("res.data", res.data);
          if (res.data.error) {
            console.log("res.data.error", res.data.error);
          } else {
            getSettingData();
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getDashboardData();
    getSettingData();
  }, []);

  const getContractBalance = async () => {
    try {
      if (settingData?.contract && settingData?.contract_abi) {
        const balwei = await web3.eth.getBalance(process.env.REACT_APP_AFFILATE_OWNER);
        const bal = await web3.utils.fromWei(balwei, "ether");
        setOwnerContractBal(bal);
      }
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getContractBalance();
  }, [settingData]);

  const onDeposit = async () => {
    settrdone(true);
    if (!address) {
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (!referral) {
      toastError("Enter Referral Address");
      settrdone(false);
      return;
    }

    // console.log("dassd", amount);

    if (!spkg) {
      toastError("Select Package !");
      settrdone(false);
      return;
    }
    // if (!tandc) {
    //     toastError("Accept terms and conditions");
    //     settrdone(false);
    //     return;
    // }

    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: address, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
      //   );
      try {
        // var stk = await contract.stake(
        //     ethers.utils.parseEther(`${amount}`),
        //     referral
        // );
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insertadmintnx",
            key: process.env.REACT_APP_KEY,
            address: address,
            ref_address: referral,
            pkg_id: spkg._id,
            // hash: receipt.transactionHash,
            token: spkg.amount,
            amount: spkg.amount,
            rate: 1,
          })
          .then((res) => {
            console.log("res", res.data);
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              toastError(res.data.error);
              return "";
            }
            settrdoneSucc(true);
            toastSuccess("Transaction done");
            setShowTopUp(false);
            setreferral("");
            setuaddress("");
            setspkg(null);
          });
        settrdone(false);
      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }


    }
    main()
  };
  useEffect(() => {
    console.log("dsdsad", pkgs);
    // setTimeout(() => {
    //   settrdoneSucc(false);
    //   setShowTopUp(false);
    // }, 2000);

  }, [pkgs, trdoneSucc]);
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className={showTopUp ? "modal fade show d-block" : "modal fade"} id="replyModal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Top Up</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setShowTopUp(!showTopUp)} />
              </div>
              <div className="modal-body">
                {/* {trdoneSucc ?
                  <>
                    <div className="text-center">
                      <img src="./assets/images/done_gif.gif" width="80" alt="" />
                      <h5 className="mt-5">Transaction done</h5>
                    </div>
                  </>
                  : */}
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label className="form-label">Mentor address</label>
                    {/* {dbuser !== null ? (
                    dbuser.ref_address !== null ? (
                      <input
                        className="form-control"
                        type="text"
                        value={referral}
                      />
                    ) : (
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Referral Address"
                        value={referral}
                        onChange={(e) => { setreferral(e.target.value); }
                        }
                      />
                    )
                  ) : ( */}
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Referral Address"
                      value={referral}
                      onChange={(e) => { setreferral((e.target.value).replace(/\s/g, "")) }
                      }
                    />
                    {/* )} */}
                  </div>
                  <div className="mb-3 col-md-12">
                    <label className="form-label">Address</label>
                    <input type="text" placeholder="Address" value={address} onChange={(e) => setuaddress((e.target.value).replace(/\s/g, ""))} className="form-control" />
                  </div>
                  {/* <div className="mb-3 col-md-12">
                  <label className="form-label">Ref. Address</label>
                  <input type="password" placeholder="Ref. Address" className="form-control" />
                </div> */}
                  {/* {pkgs.map((item, i) => {
                  return (
                    <option key={i} value={item.name}>
                      {item.name}
                    </option>
                  )
                })} */}
                  <div className="col-xl-12 col-lg-12">
                    <label className="form-label">Select Package</label>
                    <div className="mb-3">
                      <div class="dropdown show form-contro borde">
                        <button class="btn bg-current btn btn-primary dropdown-toggle text-white " type="button" onClick={() => setShowPackage(!showPackage)}>
                          {spkg !== null ? 
                          <>
                          {spkg.name}
                          (${spkg !== null ? spkg.amount : '0'}) 
                          </>
                          : 'Select Package'}

                        </button>
                        <div class={showPackage ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                          {pkgs.map((item, i) => {
                            return (
                              <a class="dropdown-item" type='button' onClick={() => { setShowPackage(!showPackage); setspkg(item) }}>{item.name}{" "}${item.amount}</a>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label">Staking Amount</label>
                    <h2 className="fw-700 font-lg">${spkg !== null ? spkg.amount : '0'}</h2>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label className="form-label">Token Rate</label>
                    <h2>{tokenRate}</h2>
                  </div>
                </div>
                {/* } */}

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => onDeposit()}>Submit</button>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body">
          <div className="container-fluid">
            <div className="form-head d-lg-flex justify-content-between">
              <div className="me-auto d-lg-flex justify-content-between mb-5">
                <h3 className="text-black font-w600">Welcome to {process.env.REACT_APP_NAME}!</h3>
                <button
                  type="button"
                  className={`btn btn-${settingData?.withdrawal === true ? "success" : "danger"} btn-sm p-1 px-3 mx-2`}
                  onClick={() => setShowTopUp(!showTopUp)}
                >Top Up
                </button>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Withdrawal Status</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {settingData ? settingData?.withdrawal === true ? "Enabled" : "Disabled" : ''}
                            <span className="fs-12">
                              <button
                                type="button"
                                className={`btn btn-${settingData?.withdrawal === true ? "success" : "danger"} btn-sm p-1 px-2 mx-2`}
                                onClick={() => updateSettingData()}
                              >
                                <i
                                  className={`fa fa-${settingData?.withdrawal === true ? "check" : "x"} fs-18`}
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Users</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData ? dashboardData?.totalusers : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Active Users</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData ? dashboardData?.totalactiveusers : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Staking Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.stakingincome ? dashboardData?.stakingincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Aggregator Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.levelincome ? dashboardData?.levelincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Community Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.rankincome ? dashboardData?.rankincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Mining Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.communityincome ? dashboardData?.communityincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Staking($)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            ${dashboardData?.staking ? dashboardData?.staking : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Staking</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.stakingtkn ? dashboardData?.stakingtkn : 0} {process.env.REACT_APP_TICKER}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Unstake</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.unstake ? dashboardData?.unstake : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Block A(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.block_a ? dashboardData?.block_a : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Block B(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.block_b ? dashboardData?.block_b : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">3X(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.user3x ? dashboardData?.user3x : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">4X(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.user4x ? dashboardData?.user4x : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">6X(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.user6x ? dashboardData?.user6x : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total BNB</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.bnbs ? dashboardData?.bnbs : 0} BNB
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Withdrawal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.withdrawal ? dashboardData?.withdrawal : 0} {process.env.REACT_APP_TICKER}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Withdrawal($)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            ${dashboardData?.withdrawalusd ? dashboardData?.withdrawalusd : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Contract Balance</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {ownerContractBal ? ownerContractBal : 0} BNB
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Restake</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.totalrestake ? dashboardData?.totalrestake : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Restake Users</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.intotalrestakeusers ? dashboardData?.intotalrestakeusers : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">User Smart Contract</p>
                        <div className="d-flex flex-wrap">
                          {!loading ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#confirmUpd"
                            >
                              <i className="fa fa-plus " aria-hidden="true" />
                            </button>
                          ) : (
                            ""
                          )}
                          <div>{loading ? "Data updating....." : ""}</div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <GoldRequest /> */}
          </div>
        </div>
        {/* <div className="modal fade" id="confirmUpd">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Are you sure??</h5>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={() => getCheck()} data-bs-dismiss="modal">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Footer />
      </div>
    </>
  );
}
