import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import parse from "html-react-parser";
import { ProjectContext } from "../../ProjectContext";
import { FaUsers } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { PiCoinsLight } from "react-icons/pi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GiRank3 } from "react-icons/gi";
import { TbBuildingCommunity } from "react-icons/tb";
import { MdOutlinePendingActions } from "react-icons/md";
import { IoIosList } from "react-icons/io";
import { GiMining } from "react-icons/gi";
import { WiSnowflakeCold } from "react-icons/wi";
import { GrStakeholder } from "react-icons/gr";
import { FaCubes } from "react-icons/fa";

export default function Sidebar() {
  const { setToggleAchieveRank, toggleAchieveRank } = useContext(ProjectContext);
  const epoint = useLocation().pathname;

  const clearToggle = () => {
    setToggleAchieveRank("");
  };

  return (
    <>
      <div className="deznav">
        <div className="deznav-scroll">
          <ul className="metismenu" id="menu">
            <li className={`${epoint === "/dashboard" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link className={`ai-icon `} to="/dashboard" aria-expanded="false">
                <RxDashboard color="white" size="1.2rem" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li
              className={`${epoint === "/users" || epoint.includes("/userreport") ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}
            >
              <Link to="/users" className="ai-icon" aria-expanded="false">
                <FaUsers color="white" size="1.2rem" />
                <span className="nav-text">Users</span>
              </Link>
            </li>
            <li className={`${epoint === "/stakings" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/stakings" className="ai-icon" aria-expanded="false">
                <GrStakeholder color="white" size="1.2rem" />
                <span className="nav-text">Stakings</span>
              </Link>
            </li>
            <li className={`${epoint === "/staking-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/staking-reward" className="ai-icon" aria-expanded="false">
                <PiCoinsLight color="white" size="1.2rem" />
                <span className="nav-text">Blast Reward</span>
              </Link>
            </li>
            <li
              className={`${epoint === "/faster-reward" ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}
            >
              <Link to="/faster-reward" className="ai-icon" aria-expanded="false">
                <FaMoneyBillTrendUp color="white" size="1.2rem" />
                <span className="nav-text">Faster Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/ancestry-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/ancestry-reward" className="ai-icon" aria-expanded="false">
                <WiSnowflakeCold color="white" size="1.2rem" />
                <span className="nav-text">Ancestry Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/expression-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/expression-reward" className="ai-icon" aria-expanded="false">
                <WiSnowflakeCold color="white" size="1.2rem" />
                <span className="nav-text">Expression Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/believe-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/believe-reward" className="ai-icon" aria-expanded="false">
                <GiRank3 color="white" size="1.2rem" />
                <span className="nav-text">Believe Reward</span>
              </Link>
            </li>
            
            {/* <li className={`${epoint === "/unstake" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/unstake" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-id-card-4" />
                <span className="nav-text">Unstake</span>
              </Link>
            </li> */}
            <li className={`${epoint === "/withdrawal" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/withdrawal" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-gift" />
                <span className="nav-text">Withdrawal</span>
              </Link>
            </li>
            <li
              className={`${epoint === "/pending-withdrawal" ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}
            >
              <Link to="/pending-withdrawal" className="ai-icon" aria-expanded="false">
                <MdOutlinePendingActions color="white" size="1.2rem" />
                <span className="nav-text">Pending-Withdrawal</span>
              </Link>
            </li>
            {/* <li className={`${epoint === "/crons" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/crons" className="ai-icon" aria-expanded="false">
                <MdOutlinePendingActions color="white" size="1.2rem" />
                <span className="nav-text">Crons</span>
              </Link>
            </li> */}
            {/* <li className={`${epoint === "/statements" ? "mm-active active-no-child" : ""}`}>
              <Link to="/statements" className="ai-icon" aria-expanded="false">
                <IoIosList color="white" size="1.2rem" />
                <span className="nav-text">Statements</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
