import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import { useParams } from "react-router";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";
import Copy from "../Comman/Copy";
import StakingIncome from "./StakingIncome";
import BeliveUserincome from "./BeliveUserincome";
import Stakings from "./Stakings";
import Unstake from "./Unstake";
import Withdrawal from "./Withdrawal";
import PendingWithdrawal from "./PendingWithdrawal";
import Statements from "./Statements";
import FasterIncome from "./FasterIncome";
import AncestryIncome from "./AncestryIncome";
import ExpressionIncome from "./ExpressionIncome";

export default function MemberReport() {
  const { address } = useParams();
  const { formatAddress } = useContext(ProjectContext);
  const [data, setdata] = useState([]);
  const [totReward, setTotReward] = useState(0);

  const getData = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "email",
        submethod: "getbyid",
        key: process.env.REACT_APP_KEY,
        addr: address,
      })
      .then((res) => {
        if (res.data.data) {
          var fbln =
            res.data.data.level_inc +
            res.data.data.expression_inc +
            res.data.data.roi_inc +
            res.data.data.staking_inc +
            res.data.data.believe_inc +
            res.data.data.believe_nft_inc -
            (res.data.data.t_withdrawal + res.data.data.pen_withdrawal);

          setTotReward(fbln);
          setdata(res.data.data);
        }
        if (res.data.error) {
          return "";
        }
      });
  };
  useEffect(() => {
    if (address) {
      getData();
    }
  }, [address]);
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="#">Member Report</a>
                </li>
              </ol>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="profile-statistics">
                  <div className="row">
                    <div className="col-12 col-md-6 mb-4">
                      <p className="text-center w-100">Address</p>
                      <h5 className="m-b-0 text-center">
                        <a
                          target="_blank"
                          className="text-primary"
                          href={`${process.env.REACT_APP_EXPLORER}address/${address}`}
                          rel="noreferrer"
                        >
                          {formatAddress(address)}
                        </a>
                        {address ? <Copy data={address} /> : ""}
                      </h5>
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                      <p className="text-center">Ref Address</p>
                      <h5 className="m-b-0 text-center">
                        {data?.ref_address ? (
                          <>
                            <a
                              target="_blank"
                              className="text-primary"
                              href={`${process.env.REACT_APP_EXPLORER}address/${address}`}
                              rel="noreferrer"
                            >
                              {data?.ref_address ? formatAddress(data?.ref_address) : ""}
                            </a>
                            {data?.ref_address ? <Copy data={data?.ref_address} /> : ""}
                          </>
                        ) : (
                          "-"
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <div className="row text-center">
                        <h3>LikeME Rewards</h3>
                        <hr />
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Balance</p>
                          <h5 className="text-center">$ {(totReward).toFixed(4)}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Blast</p>
                          <h5 className="text-center">$ {data ? Number(data.staking_inc).toFixed(2) : 0}   </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Faster</p>
                          <h5 className="text-center">$  {data ? Number(data.level_inc).toFixed(2) : 0}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Ancestry</p>
                          <h5 className="text-center">$ {data ? Number(data.roi_inc).toFixed(2) : 0}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Expression</p>
                          <h5 className="text-center">$ {data ? Number(data.expression_inc).toFixed(2) : 0}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Believe</p>
                          <h5 className="text-center">$ {data ? Number(data.believe_inc).toFixed(2) : 0}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Achievement</p>
                          <h5 className="text-center">$ {data ? Number(data.believe_nft_inc).toFixed(2) : 0} </h5>
                        </div>
                      </div>
                      <div className="row text-center">
                        <h3>LikeME Statistics</h3>
                        <hr />
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Total Stake</p>
                          <h5 className="text-center">$ {data ? data.t_staking : 0} </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Direct Team</p>
                          <h5 className="text-center">{data ? data.d_team : 0}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Total Team</p>
                          <h5 className="text-center">{data ? data.team : 0}</h5>
                        </div>

                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Open Ancestry</p>
                          <h5 className="text-center">{data ? Number(data.open_ancestry).toFixed(0) : 0} </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Total Business</p>
                          <h5 className="text-center">
                            $ {data ? data.team_business : 0} 
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Freeze Expression</p>
                          <h5 className="text-center">$  {data ? data.f_total - data.f_given : 0}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Release Expression</p>
                          <h5 className="text-center">
                         $ {data ? data.f_given : 0}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Rank</p>
                          <h5 className="text-center">{data ? data.rank === null ? 'No Rank' : data.rank : '-'}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Next Rank</p>
                          <h5 className="text-center">{data ? data.next_rank : '-'}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Withdrawal</p>
                          <h5 className="text-center">
                          $ {data ? data.t_withdrawal : 0}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Pending Withdrawal</p>
                          <h5 className="text-center">$ {data ? data.pen_withdrawal : 0}</h5>
                        </div>
                      </div>
                      <div className="row text-center">
                        <h3>Business</h3>
                        <hr />
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">A Team</p>
                          <h5 className="text-center">
                          $ {data ? data.legA : 0} 
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">B Team</p>
                          <h5 className="text-center">$ {data ? data.legB : 0}  </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Other Team</p>
                          <h5 className="text-center">
                            $  {data ? data.legC : 0}  
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Req. A Team</p>
                          <h5 className="text-center">
                            $ {data ? data.r_legA : 0} 
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Req. B Team</p>
                          <h5 className="text-center">
                            $ {data ? data.r_legB : 0}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Req. Other Team</p>
                          <h5 className="text-center">
                            $ {data ? data.r_legC : 0} 
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Stakings address={address} />
            <StakingIncome address={address} />
            <FasterIncome address={address} />
            <AncestryIncome address={address} />
            <ExpressionIncome address={address} />
            <BeliveUserincome address={address} />
            <Withdrawal address={address} />
            <PendingWithdrawal address={address} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
