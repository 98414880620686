import Index from "./Components/Home/Index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Members from "./Components/Members/Members";
import StakingIncome from "./Components/Report/StakingIncome";
import Withdrawal from "./Components/Report/Withdrawal";
import MemberReport from "./Components/Members/MemberReport";
import Login from "./Components/Login";
import LevelIncome from "./Components/Report/LevelIncome";
import ExpressionIncome from "./Components/Report/ExpressionIncome";
import RoiOfRoi from "./Components/Report/RoiOfRoi";
import Stakings from "./Components/Report/Stakings";
import Unstake from "./Components/Report/Unstake";
import PendingWithdrawal from "./Components/Report/PendingWithdrawal";
import Cron from "./Components/Cron";
import BelieveIncome from "./Components/Report/BelieveIncome";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Index />} />
        <Route path="/users" element={<Members />} />
        <Route path="/stakings" element={<Stakings />} />
        <Route path="/userreport/:address" element={<MemberReport />} />
        <Route path="/staking-reward" element={<StakingIncome />} />
        <Route path="/faster-reward" element={<LevelIncome />} />
        <Route path="/ancestry-reward" element={<RoiOfRoi />} />
        <Route path="/expression-reward" element={<ExpressionIncome />} />
        <Route path="/believe-reward" element={<BelieveIncome />} />
        <Route path="/unstake" element={<Unstake />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/pending-withdrawal" element={<PendingWithdrawal />} />
        <Route path="/crons" element={<Cron />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
