import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import { ProjectContext } from "../../ProjectContext";
import { Link } from "react-router-dom";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";

export default function Members() {
  const { formatAddress, toastSuccess, toastError } = useContext(ProjectContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [userwithdrawl, setuserwithdrawl] = useState(false);
  const [editAddress, setEditAddress] = useState("");

  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "email",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
          page: currentPage,
          pageSize: pageSize,
          search,
        })
        .then((res) => {
          setdata(res.data.data);
          setuserwithdrawl(res.data.data.withdrawal_status);
          setPages(Math.ceil(res.data.dataLength / pageSize));
          setLoading(false);
          if (res.data.error) {
            return "";
          }
        });
    } catch (err) {
      setLoading(false);
    }
  };

  const saveBonusIncome = async (editAddress) => {
    try {
      console.log("editAddress", editAddress)
      if (!editAddress) {
        // setBonusIncomeErr("Address not found.");
        return
      }
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "withdrawal",
          submethod: "update",
          // withdrawal_status: data.withdrawal_status === true ? false : true,
          address: editAddress,
          key: process.env.REACT_APP_KEY,
        })
        .then((res) => {
          if (res.data.error) {
            // toastError("Withdrawal Status Not Update!");
          }
          toastSuccess("Withdrawal Status Update!");
          getData();
        });

    } catch (err) {
      console.log("error : ", err);
      // setBonusIncomeErr(err.message);
    }
  };



  useEffect(() => {
    getData();
  }, [pages, pageSize, currentPage]);

  useEffect(() => {
    getData();
  }, [search]);


  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">Users</li>
              </ol>
            </div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-center">
              <div className="input-group search-area d-inline-flex me-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="input-group-text"
                    onClick={getData}
                  >
                    <i className="flaticon-381-search-2" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body ">
                    <div className="d-flex justify-content-between">
                      <div className="card-title text-light">Members</div>
                      <span
                        className="btn btn-primary btn-sm "
                        onClick={() => getData()}
                      >
                        <i
                          className="flaticon-381-repeat-1 "
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Withdrawal Status</th>
                            <th>Date & Time</th>
                            <th>Staking</th>
                            <th>Daily(%)</th>
                            <th>Address</th>
                            <th>Reff Address</th>
                            <th>Status</th>
                            {/* <th>Amount</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data?.length
                            ? data.map((ele, i) => {
                              let ind =
                                currentPage * pageSize - pageSize + (i + 1);
                              return (
                                <tr>
                                  <td>{ind}</td>
                                  <td>
                                    <div className="form-check">
                                      {/* <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="withdrawCheck1"
                                        checked={userwithdrawl} // It will be true or false based on the initial value
                                        onChange={(e) => {
                                          setuserwithdrawl(e.target.checked); // Update the state
                                          saveBonusIncome(ele.address); // Call the function with the address
                                        }}
                                      /> */}

                                      <button className="btn-primary px-2 rounded" onClick={() => saveBonusIncome(ele.address)}>
                                        {ele.withdrawal_status === true ? (
                                          <>
                                            <div className="m-2 text-green"><i className="fa fa-check text-green" /></div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="m-2 text-danger"><i className="fa fa-times text-danger" /></div>
                                          </>
                                        )}
                                      </button>
                                    </div>

                                  </td>
                                  <td>{ele.createdAt}</td>
                                  <td>
                                    $ {ele.t_staking}
                                  </td>
                                  <td>
                                    $ {ele.d_per}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}address/${ele.address}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(ele.address)}
                                    </a>
                                    {ele.address ? (
                                      <Copy data={ele.address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}address/${ele.ref_address}`}
                                      rel="noreferrer"
                                    >
                                      {ele.ref_address
                                        ? formatAddress(ele.ref_address)
                                        : "-"}
                                      &nbsp;
                                    </a>
                                    {ele.ref_address ? (
                                      <Copy data={ele.ref_address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>

                                  <td>
                                    <div className="d-flex align-items-center">
                                      {ele.status === true ? (
                                        <>
                                          <div className="green-dot-active mx-2"></div>
                                          Active
                                        </>
                                      ) : (
                                        <>
                                          <div className="red-dot-active mx-2"></div>
                                          Deactive
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <span className="me-3">
                                      <Link
                                        className="edit-appointment"
                                        type="button"
                                        to={`/userreport/${ele.address}`}
                                      >
                                        <i
                                          className="fa fa-book fs-18 "
                                          aria-hidden="true"
                                        />
                                      </Link>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !data || !data.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div >

    </>
  );
}
